import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Form, InputGroup, Spinner, } from "react-bootstrap";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import MyPagination from "../utilities/Pagination";
import { fetchApprovedLoanRequest } from "../../store/actions/adminActions";
import moment from "moment";
import format from "format-number";
import Swal from "sweetalert2";
import LoanContractModal from "./LoanContractPreviewer";
import LoanDetailModal from "./LoanDetailModal";
import CustomModal from "../utilities/CustomModal";
import GuarantorPreview from "../forms/GuarantorPreview";
import DebitAuthorityModal from "./DebitAuthourityModal";



function LoanSummary() {
  const [currPage, setCurrPage] = useState(1);
  const { loanRequests, isLoading, loanRequestPageNum: numberOfPages } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showGuarantorModal, setShowGurantorModal] = useState(false)
  const [showContractModal, setShowContractModal] = useState(false);
  const [showDebitAuthority, setShowDebitAuthority] = useState(false);

  useEffect(() => {
    dispatch(fetchApprovedLoanRequest(1));
  }, [dispatch]);

  const flattenObj = (obj) => {
    return Object.keys(obj).reduce((result, key) => {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        return { ...result, ...flattenObj(obj[key]) };
      }
      return { ...result, [key]: obj[key] };
    }, {});
  };

  const processedLoans = loanRequests.map((loan, index) => ({
    ...flattenObj(loan),
    serial: index + 1,
    deductedInterest: format({ prefix: "₦" })((loan.proRata * loan.amount) / 100),
    proRata: format({ suffix: "%" })(loan.proRata),
    handlingCharge: format({ prefix: "₦" })(loan.amount * 0.005),
    payable: format({ prefix: "₦" })(loan.payable),
    time: moment(loan.requestedAt).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  }));

  const afterPageClicked = (pageNum) => {
    setCurrPage(pageNum);
    dispatch(fetchApprovedLoanRequest(pageNum, searchTerm));
  };

  const onSearch = () => {
    dispatch(fetchApprovedLoanRequest(1, searchTerm));
    setCurrPage(1);
  };

  const onClearSearch = () => {
    setSearchTerm("");
    dispatch(fetchApprovedLoanRequest(1, ""));
    setCurrPage(1);
  };

  const createColumn = (dataField, text) => ({
    dataField,
    text,
    headerStyle: { backgroundColor: "#c8e6c9", width: "200px" },
  });

  const columns = [
    createColumn("serial", "#"),
    createColumn("name", "Name"),
    // createColumn("ippisNum", "IPPIS number"),
    // createColumn("staffNum", "Staff Number"),
    createColumn("memberId", "Member Id"),
    createColumn("amount", "Loan Amount(₦)"),
    createColumn("loanType", "Loan Type"),
    createColumn("duration", "Duration (months)"),
    createColumn("interestRate", "Interest rate(%)"),
    createColumn("proRata", "Pro-rated Percentage Interest(%)"),
    createColumn("deductedInterest", "Interest Deducted at source"),
    createColumn("handlingCharge", "Handling charge deducted at source"),
    createColumn("payable", "Amount Payable Per month"),
  ];

  const MyExportCSV = (props) => (
    <div className="d-flex justify-content-end mb-2">
      <Button variant="primary" onClick={props.onExport}>
        Export To CSV
      </Button>
    </div>
  );

  const rowEvents = {
    onClick: (e, row) => {
      setSelectedLoan(row);
      setShowModal(true);
    },
  };




  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <KanbanBoardContainer>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Loan Requests</h3>
        </div>
        <div className="card-body">
          <ToolkitProvider
            keyField="_id"
            data={processedLoans}
            columns={columns}
            exportCSV
          >
            {(props) => (
              <>
                <MyExportCSV {...props.csvProps} />
                <div className="d-flex mb-3">
                  <InputGroup>
                    <Form.Control
                      style={{ flexGrow: 1 }}
                      type="text"
                      placeholder="Search loans by LoanID or MemberId..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && onSearch()}
                    />
                    <Button variant="outline-success" onClick={onSearch}>
                      Search
                    </Button>
                    <Button variant="danger" onClick={onClearSearch}>
                      Clear
                    </Button>
                  </InputGroup>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  striped
                  hover
                  wrapperClasses="table-responsive"
                  rowEvents={rowEvents}
                />
                <MyPagination
                  currentPage={currPage}
                  numberOfPages={numberOfPages}
                  pageClicked={afterPageClicked}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </div>
      <LoanDetailModal
        show={showModal}
        onHide={() => setShowModal(false)}
        loan={selectedLoan}
        setShowContractModal={setShowContractModal}
        showGuarantor={setShowGurantorModal}
        setShowAuthority={setShowDebitAuthority}
      />
      <CustomModal hide={() => setShowGurantorModal(false)} show={showGuarantorModal} size='xl' >
        <GuarantorPreview data={selectedLoan} />
      </CustomModal>
      <LoanContractModal show={showContractModal}
        onHide={() => setShowContractModal(false)}
        loanDetails={selectedLoan} />
      <DebitAuthorityModal show={showDebitAuthority} onHide={() => setShowDebitAuthority(false)}
        loanDetails={selectedLoan} />


    </KanbanBoardContainer>
  );
}

export default LoanSummary;